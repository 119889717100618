import React from "react"
import ReactDOM from 'react-dom'
import { useSpring, animated } from 'react-spring'
import { Link } from "gatsby"
import styled, { withTheme } from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import Container, { Row, Third, TwoThird, Half } from "../layout/container"
import Brands from "../ui/brands"
//import Book from "../../images/illustrations/book.png"

const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1.1]
const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

const Home = ({ theme }: any) => {
  const Section = styled.section`
    background: linear-gradient(46.78deg, rgba(19, 54, 69, 0.4) 0%, rgba(0, 0, 0, 0) 102.78%), #449EDD;
    background-blend-mode: multiply, normal;
    color: ${theme.colors.light};
    //margin-top: -2rem;
    // height: 90vh;
    // min-height: 768px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      color: ${theme.colors.white};
      max-width: 850px;
      margin: 0 auto 3rem;
      padding-top: 5.5rem;
      font-size: 40px;
      line-height: 1;
      @media screen and (max-width: ${theme.breakpoints.phone}) {
        font-size: 40px;
        margin-bottom: 1.5rem;
      }
    }
    p {
      max-width: 850px;
      margin: 0 auto;
      box-sizing: border-box;
      margin-bottom: 3rem;
      font-size: 1.1rem;
    }
    .stick {
      margin-right: -5rem;
      @media screen and (max-width: ${theme.breakpoints.phone}) {
        display: none;
      }
    }

    .card {
      width: auto;
      height: 500px;
      background-image: url(https://res.cloudinary.com/yumyoshojin/image/upload/v1634824086/alan/book.png);
      background-size: cover;
      background-position: center center;
      //box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
      transition: box-shadow 0.5s;
      will-change: transform;
      
    }

    .card:hover {
      //box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 0.4);
    }
    

    
  `
  const Button = styled(Link)`
  font-size: 18px;
  padding: 14px 44px;
  margin: 2rem 0;
  font-family: ${props => props.theme.fontBold};
  background: ${props => props.theme.colors.secondary};
  border: 2px solid ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.primary} !important;
  text-decoration: none;
  transition: 0.3s;
  &:hover {
    background: transparent;
    border: 2px solid ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.white} !important;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
        float: left;
  }
`
// document.addEventListener("scroll", function(event) {
//   const x = event.pageX
//   const y = event.pageY
  
//   const midX = x - window.innerWidth / 2
//   const midY = y - window.innerHeight / 2 
  
  
  
//   const box = document.querySelector(".threed")
  
//   box.style.left = x + "px"
//   box.style.top = y + "px"
  
//   box.style.transform = "rotateX(" + (midY * 0.2) + "deg) rotateY(" + (midX * 0.2) + "deg)"
// })


  const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } }))

  return (
    <Section id="home">
      <Container>
        <Row>
          <Third>
            <div>
          <h1>Marketing:<br></br>
            The Bottom Line</h1>
          <p>
            Moving marketing from a perceived cost centre into a strategic driver of profit
          </p>
          <Button to="#contact">
            Register your interest
          </Button>
            </div>
          </Third>
          <TwoThird>
            
            <div className="stick">
              <animated.div
                class="card"
                onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                onMouseLeave={() => set({ xys: [0, 0, 1] })}
                style={{ transform: props.xys.interpolate(trans) }}
              />
              {/* <StaticImage src="../../images/illustrations/book.png" alt="Marketing: The Bottom Line" /> */}
              {/* <div className="threed">
                <div className="face face-cover"></div>
                <div className="face face-spine"></div>
                <div className="face face-back"></div>
                <div className="face face-top"></div>
                <div className="face face-side"></div>
                <div className="face face-bottom"></div>
              </div> */}
            </div>
          </TwoThird>
        </Row>
      </Container>
    </Section>
  )
}

export default withTheme(Home)
