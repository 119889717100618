import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"

import Home from "../components/sections/home"
import About from "../components/sections/about"
import Content from "../components/sections/content"
import Author from "../components/sections/author"
import Quotes from "../components/sections/quotes"
import Contact from "../components/sections/contact"

const IndexPage = () => {
  return (
    <Layout headerClass="home">
      <SEO
        title="Marketing: The Bottom Line"
        description="How you can move marketing from a perceived cost centre into a strategic driver of profit? Agency CEO, and former salesman, Richard Hadler explains."
      />
      <Home />
      <About />
      <Content />
      <Author />
      <Quotes />
      <Contact />
    </Layout>
  )
}

export default IndexPage
