import React from "react"
import styled, { withTheme } from "styled-components"

import Container, { Row, OneQuarter, ThreeQuarter, Half } from "../layout/container"
import List from "../ui/list"

import Bullseye from "../../images/illustrations/bullseye.svg"

const Content = ({ theme }: any) => {
  const Section = styled.section`
    padding: 4rem 0 5rem;
    background: ${theme.colors.secondary};
    @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
      padding: 2rem 0;
    }

    h1 {
      text-align: center;
      font-size: 70px;
      @media screen and (max-width: ${props =>
          props.theme.breakpoints.tablet}) {
        font-size: 50px;
      }

      & + p {
        margin-bottom: 5rem;
      }
    }

    svg {
      //max-width: 33%;
      width: 300px;
      @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
            max-width: 20%;
    height: 100px;
        margin: 0 auto;
      }
      & + div {
        width: 60%;
        margin-bottom: 3rem;
        @media screen and (max-width: ${props =>
            props.theme.breakpoints.phone}) {
          width: 100%;
        }
      }
    }

    h3 {
      font-size: 55px;
      margin-top: 0;
      margin-bottom: 1.5rem;
      @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
        font-size: 40px;
        text-align: center;
      }
      span {
        font-size: 35px;
        font-weight: normal;
        font-style: italic;
        color: ${theme.colors.accent};
        @media screen and (max-width: ${props =>
            props.theme.breakpoints.phone}) {
          font-size: 25px;
        }
      }
    }

    li {
      font-size: 16px;
    }
  `



  return (
    <React.Fragment>
      <Section id="content">
        <Container>
          <Row>
            <OneQuarter>
            <Bullseye />
            </OneQuarter>
            <ThreeQuarter>
              <h4>
                Content includes:
              </h4>
                <Row>
                <Half>
                  <List>
                    <li>How marketing has become stuck in yesterday’s methodologies</li>
                    <li>Why marketing must make the first move to solve the sales/marketing divide</li>
                    <li>How marketing can make sales want them, love them and <strong>need</strong> them</li>
                  </List>
                </Half>
                <Half>
                <List>
                    <li>Introduce the Marketer’s Hierarchy of Need model</li>
                    <li>The substantial, accumulative value of joined-up ‘revenuist’ working</li>
                    <li>How marketers can escape the budget and deliver ROI through creativity</li>
                </List>
                </Half>
              </Row>
            </ThreeQuarter>
          </Row>
        </Container>
      </Section>
    </React.Fragment>
  )
}

export default withTheme(Content)
