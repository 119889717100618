import React from "react"
import styled, { withTheme } from "styled-components"

import Container, { Row, OneQuarter, ThreeQuarter, Half } from "../layout/container"
import Rich from "../../images/illustrations/rich.svg"
import Quote from "../../images/quote.svg"

const Author = ({ theme }: any) => {
  const Section = styled.section`
    padding: 4rem 0 5rem;
    background: ${theme.colors.white};
    @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
      padding: 2rem 0;
    }

    h1 {
      text-align: center;
      font-size: 70px;
      @media screen and (max-width: ${props =>
          props.theme.breakpoints.tablet}) {
        font-size: 50px;
      }

      & + p {
        margin-bottom: 5rem;
      }
    }

    svg {
      //max-width: 33%;
      //width: 300px;
      @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
            max-width: 20%;
    height: 100px;
        margin: 0 auto;
      }
      
    }

    h3 {
      font-size: 55px;
      margin-top: 0;
      margin-bottom: 1.5rem;
      @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
        font-size: 40px;
        text-align: center;
      }
      span {
        font-size: 35px;
        font-weight: normal;
        font-style: italic;
        color: ${theme.colors.accent};
        @media screen and (max-width: ${props =>
            props.theme.breakpoints.phone}) {
          font-size: 25px;
        }
      }
    }

    .rich {
      h5 {
        color: ${theme.colors.accent};
        margin-bottom: 0;
        font-family: ${theme.fontBold};
      }
      p {
        font-size: 14px;
        font-family: ${theme.fontBold};
      }
    }

    .quote {
      p {
        color: #449EDD;
        font-family: ${theme.fontBold};
        margin-top: 0;
        font-size: 22px;
      }
    }
  `



  return (
    <React.Fragment>
      <Section id="author">
        <Container>
          <Row>
            <OneQuarter>
              <Rich />
              <div className="rich">
                <h5>Richard Hadler</h5>
                <p>CEO, alan. agency and Author of Marketing:<br></br>The Bottom Line</p>
              </div>
            </OneQuarter>
            <ThreeQuarter>
              <h4>
                About the author
              </h4>
              <div className="quote">
                <Quote />
                <p>I want to help tip the scales in Marketing’s favour</p>
              </div>
                  <p>
                    Richard began his working life, age 16, serving tables and behind the bar at a holiday park in the New Forest. He quickly recognised that becoming memorable to guests would earn him more tips. One such incident, a favourite with diners and involving a bottle of tomato sauce (read the book to learn more!), got him noticed by the park’s new boss. It was ultimately also responsible for him cancelling his university plans. Instead, he became his boss’s protégé, gained an Open University degree and embarked on a speedy climb through the ranks and across all the park’s departments. 
                  </p>
                  <p>
                    When his boss made a throwaway comment one day, Richard realised it was time to move on. That comment? “Rich, you’ve gone all pipe and slippers.” He was 21 and had reached the ceiling in that business. 
                  </p>
                  <p>
                    He quickly secured a role with a high-ranking London publishing firm. Soon after starting, he realised he hated all that firm stood for and quit to join Raconteur, the renowned City firm that provides high-quality content for The Times, Sunday Times and top B2B brands like Google, Deloitte, Dell Technologies and more. 
                  </p>
                  <p>
                    A few years later, he became Raconteur’s Commercial Director before becoming MD of alan. Agency in 2020 and CEO of both alan. and Sectorlight Marketing (both part of The Raconteur Group) in 2021. 
                  </p>
                  <p>
                    As a former salesman, he has seen firsthand the disadvantage businesses put themselves at by failing to get sales and marketing to collaborate. Like many salespeople, he’ll confess, he wasn’t always a fan of marketing in his early career. But - unlike many salespeople - he soon realised the skills are complementary. Winning campaigns happen when both sides work together. This is why collaboration between sales and marketing is the key theme of his first book. 
                  </p>
                  <p>
                    If you’re wondering whether such a crazy business life has room for a personal life – yes, it does! Richard is engaged to his partner of 6 years, Bethany and the proud father of his 1-year-old cockapoo, Rory. He is an avid Arsenal fan and loves to holiday in the sun… as much as his schedule allows.
                  </p>
                
            </ThreeQuarter>
          </Row>
        </Container>
      </Section>
    
    </React.Fragment>
  )
}

export default withTheme(Author)
