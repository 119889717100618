import React, { useState } from "react"
import styled from "styled-components"

import { Row, Half } from "../layout/container"
import { ActiveCampaignForm } from "../ui/activecampaign-form"
import List from "../ui/list"

const Section = styled.section`
  padding: 5rem 0 1rem;
  min-height: 65vh;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.primary};
  a {
    color: ${props => props.theme.colors.white};
  }
  form {
    margin-bottom: 3rem;
    margin-top: 3rem;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 3rem 0 0;

    form {
      margin-top: 3rem;
      
    }
  }

  h2 {
    color: ${props => props.theme.colors.white};
    font-family: ${props => props.theme.fontBold};
    font-size: 24px;
    
  }

  p {
    margin-top: 0;
  }

  .visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  input,
  select {
    display: block;
    width: 100%;
    font-size: 16px;
    padding: 7px 11px;
    box-sizing: border-box;
    font-family: ${props => props.theme.font};
    border: none;
    margin-bottom: 1rem;
    background: ${props => props.theme.colors.white};
    border-radius: 0;
  }

  button {
    width: 50%;
    font-size: 14px;
    padding: 15px 11px;
    font-family: ${props => props.theme.font};
    background: ${props => props.theme.colors.secondary};
    text-transform: uppercase;
    border: none;
    color: ${props => props.theme.colors.primary};
    display: block;
    &:hover {
      background: lighten(${props => props.theme.colors.primary}, 10%);
    }
  }
`

const MultiLineInput = styled(`textarea`)`
  padding: 7px 11px;
  font-size: 16px;
  background-color : ${(props) => props.theme.colors.white}; 
  border-width: 1px;
  resize: vertical;
  border-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.primary};
  border-style: solid;
  border-radius: 0px;
  font-family: ${(props) => props.theme.font};
  min-width: -webkit-fill-available;
  margin-bottom: 10px;
`

const Container = styled.div`
  width: 440px;
  max-width: 90%;
  margin: 0 auto;
`

const Success = styled.div`
  background: ${props => props.theme.colors.light};
  padding: 1rem;
  box-sizing: border-box;
`

const Error = styled.div`
  background: #ecdcdc;
  padding: 1rem;
  box-sizing: border-box;
`

const Contact = () => {
  const [sent, send] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [phone, setPhone] = useState<string>("")
  const [company, setCompany] = useState<string>("")
  const [message, setMessage] = useState<string>("")

  const encode = (data: any) => {
    return Object.keys(data)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join("&")
  }

  const clearForm = () => {
    setFirstName("")
    setLastName("")
    setEmail("")
    setPhone("")
    setCompany("")
    setMessage("")
  }

  function handleSubmit(e: any) {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        email,
        phone,
        company,
        "form-name": "contact",
        first_name: firstName,
        last_name: lastName,
      }),
    })
      .then(() => {
        send(true)
        clearForm()
      })
      .catch(err => setError(err))

    e.preventDefault()
  }

  return (
    <Section id="contact">
      <Container>
        <h2>Register your interest</h2>
        
        <p>Register your interest using the form below or reach out at <a href="mailto:richard@alan-agency.com">richard@alan-agency.com</a>.</p>
         <ActiveCampaignForm />
            {/* {error && <Error>Please fill in all the fields of the form!</Error>}
            {sent ? (
              <Success>Thanks, we will be in touch with you shortly!</Success>
            ) : (
              <form
                name="contact"
                method="post"
                action="/#contact"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={e => handleSubmit(e)}
              >
                <input type="hidden" name="form-name" value="contact" />
                <input type="hidden" name="bot-field" />
                <label htmlFor="first_name" className="visuallyhidden">
                  First name
                </label>
                <input
                  className="input"
                  type={"text"}
                  name={"first_name"}
                  id={"first_name"}
                  placeholder={"First name"}
                  required={true}
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                />
                <label htmlFor="last_name" className="visuallyhidden">
                  Last name
                </label>
                <input
                  className="input"
                  type={"text"}
                  name={"last_name"}
                  id={"last_name"}
                  placeholder={"Last name"}
                  required={true}
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                />
                <label htmlFor="email" className="visuallyhidden">
                  Work Email
                </label>
                <input
                  className="input"
                  type={"email"}
                  name={"email"}
                  id={"email"}
                  placeholder={"Email"}
                  required={true}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
                <label htmlFor="company" className="visuallyhidden">
                  Company name
                </label>
                <input
                  className="input"
                  type={"text"}
                  name={"company"}
                  id={"company"}
                  placeholder={"Company"}
                  required={true}
                  value={company}
                  onChange={e => setCompany(e.target.value)}
                />
                <MultiLineInput
                  className="input"
                  type={"textarea"}
                  name={"message"}
                  id={"message"}
                  placeholder={"Message"}
                  required={true}
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                />
                <button type="submit">Send Message</button>
              </form>
            )} */}
      </Container>
    </Section>
  )
}

export default Contact

