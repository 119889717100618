import React from "react"
import styled, { withTheme } from "styled-components"

import Container, { Row, OneQuarter, ThreeQuarter, Half } from "../layout/container"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Rich from "../../images/illustrations/rich.svg"
import Quote from "../../images/quote.svg"

const Quotes = ({ theme }: any) => {
  const Section = styled.section`
  .slick-prev:before, .slick-next:before { 
      color: ${theme.colors.primary} !important;
    }
    padding: 4rem 0 5rem;
    background: ${theme.colors.secondary};
    color: ${theme.colors.primary};
    @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
      padding: 2rem 0;
    }

    h1 {
      text-align: center;
      font-size: 70px;
      @media screen and (max-width: ${props =>
          props.theme.breakpoints.tablet}) {
        font-size: 50px;
      }

      & + p {
        margin-bottom: 5rem;
      }
    }

    svg {
      //max-width: 33%;
      //width: 300px;
      @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
            max-width: 20%;
    height: 100px;
        margin: 0 auto;
      }
      
    }

    h3 {
      font-size: 55px;
      margin-top: 0;
      margin-bottom: 1.5rem;
      @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
        font-size: 40px;
        text-align: center;
      }
      span {
        font-size: 35px;
        font-weight: normal;
        font-style: italic;
        color: ${theme.colors.accent};
        @media screen and (max-width: ${props =>
            props.theme.breakpoints.phone}) {
          font-size: 25px;
        }
      }
    }

    .rich {
      h5 {
        color: ${theme.colors.accent};
        margin-bottom: 0;
        font-family: ${theme.fontBold};
      }
      p {
        font-size: 14px;
        font-family: ${theme.fontBold};
      }
    }

    .quote {
      p {
        color: #449EDD;
        font-family: ${theme.fontBold};
        margin-top: 0;
        font-size: 22px;
      }
    }
  `
  const Quote = styled.div`
    
    padding: 1rem 1rem;
 
    margin-bottom: 1rem;
    blockquote {
      font-family: ${(props) => props.theme.fontBold};
      padding: 2.5rem 0rem 0.5rem 0 !important;
      background-position: inherit;
      margin: 0 2.5rem;
      @media screen and (max-width: ${(props) =>
          props.theme.breakpoints.tablet}) {
        font-size: 25px;
        margin-right: 5rem;
      }
      @media screen and (max-width: ${(props) => props.theme.breakpoints.phone}) {
        font-size: 20px;
        margin-right: 2rem;
        margin-left: 0;
      }
    }
    p {
      margin: 1rem 2.5rem;
      @media screen and (max-width: ${(props) => props.theme.breakpoints.phone}) {
        margin: 1rem 0;
      }
    }
  `
    
const sliderSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    pauseOnHover: true,
  };

  return (
    <React.Fragment>
      <Section id="author">
        <Container>
          
              <Slider {...sliderSettings}>
                    <Quote>
                      <blockquote>“Undeniably, Rich is one of the most commercially talented people I will ever have the honour of working for. His relentless enthusiasm for all things sales and marketing is not just a force to be reckoned with but a constant inspiration to all those around him. Marketing: The Bottom Line is one more example of Rich’s expertise and practical, no bs approach to encouraging marketing and sales to work with, not against each other.”</blockquote>
                      <p>Tom Andrews, Rev Ops Lead at Permutive</p>
                    </Quote>
                    <Quote>
                      <blockquote>“The future of B2B marketing has come faster than you thought... and it ain’t what it used to be. Now, Data, Customer and Insights-based marketing is the only option for CMOs and their teams – so ‘Do or Die’…and take your board and sales teams along your journey with this book.”</blockquote>
                      <p>Philippe Ruttens, Fractional CMO & Revenue Marketeer</p>
                    </Quote>
                    <Quote>
                      <blockquote>“A lot has been written and said on the relationship between sales and marketing. One of the few things everybody can agree on is: alignment is a huge enabler of business growth. Richard’s experience on both sides gives him a uniquely informed perspective. Framed with practical, real-world advice rather than theory and jargon, this is one of the books on the subject well worth reading.”</blockquote>
                      <p>Simon Lipscomb, Sales & Marketing Director at Efficio</p>
                    </Quote>
                    <Quote>
                      <blockquote>“Today, B2B Marketers need more than ever to clearly understand the sales process and how Sales and Marketing work together. In my experience, Rich and his team consistently hold both sides of this equation up to the mirror. You will understand what I mean if you’ve worked on both sides. If you haven’t had a chance to experience both sides, then this book will help you in your journey.”</blockquote>
                      <p>Kerry Bridge-Collier, Global Marketing Director at Bazaarvoice</p>
                    </Quote>
                    <Quote>
                      <blockquote>“Rich is a marketing phenomenon. Look at his record. He sells to the world’s biggest companies and builds relationships for the long term. We’ve worked together for a decade, and I’ve watched Rich train dozens of young professionals to be world class marketers. The book captures everything he knows of the industry. Highly recommended.”</blockquote>
                      <p>Charles Orton-Jones, Freelance Business Journalist</p>
                    </Quote>
                 
              </Slider>
        
        </Container>
      </Section>
    
    </React.Fragment>
  )
}

export default withTheme(Quotes)
