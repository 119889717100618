import React from "react"
import styled, { withTheme } from "styled-components"

const List = ({ theme, children }: any) => {
  const Ul = styled.ul`
    font-size: 18px;
    list-style: none;
    margin: 0;
    padding: 0;
    //padding-inline-start: 1.5rem;
    li {
      margin-bottom: 1rem;
      word-break: keep-all;
      line-height: 1.8;
      &:before {
        position: relative;
        left: 0px;
        top: 24px;
        height: 60px;
        color: #D33544;
        font-size: 35px;
        line-height: 1px;
        width: 20px;
        float: left;
        color: transparent;
        margin-right: 0.75rem;
        content: "1";
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='20px' height='6px' viewBox='0 0 20 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='CASE-STUDY---KPMG' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='CASE-STUDY-TEMPLATE---1920px-(STATS)' transform='translate(-392.000000, -6308.000000)' fill='%23D33544'%3E%3Cg id='Group-6' transform='translate(0.000000, 5678.000000)'%3E%3Crect id='Rectangle-Copy-7' x='392' y='630' width='20' height='6'%3E%3C/rect%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      }
    }
  `;

  return <Ul>{children}</Ul>
}

export default withTheme(List)
