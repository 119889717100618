import React from "react"
import styled, { withTheme } from "styled-components"

import Container, { Row, OneQuarter, ThreeQuarter, Half } from "../layout/container"
import Pie from "../../images/illustrations/pie.svg"

const About = ({ theme }: any) => {
  const Section = styled.section`
    z-index: 9999;
    position: relative;
    padding: 4rem 0 5rem;
    background: ${theme.colors.primary};
    color: ${theme.colors.white};
    @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
      padding: 2rem 0;
    }

    h1 {
      text-align: center;
      font-size: 70px;
      @media screen and (max-width: ${props =>
          props.theme.breakpoints.tablet}) {
        font-size: 50px;
      }

      & + p {
        margin-bottom: 5rem;
      }
    }
    span {
      font-family: ${props => props.theme.fontBold}
    }

    svg {
      //max-width: 33%;
      width: 300px;
      @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
        max-width: 20%;
        height: 100px;
        margin: 0 auto;
      }
      & + div {
        width: 60%;
        margin-bottom: 3rem;
        @media screen and (max-width: ${props =>
            props.theme.breakpoints.phone}) {
          width: 100%;
        }
      }
    }

    h3 {
      font-size: 55px;
      margin-top: 0;
      margin-bottom: 1.5rem;
      @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
        font-size: 40px;
        text-align: center;
      }
      span {
        font-size: 35px;
        font-weight: normal;
        font-style: italic;
        color: ${theme.colors.accent};
        @media screen and (max-width: ${props =>
            props.theme.breakpoints.phone}) {
          font-size: 25px;
        }
      }
    }
  `



  return (
    <React.Fragment>
      <Section id="about">
        <Container>
          <Row>
            <OneQuarter>
            <Pie />
            </OneQuarter>
            <ThreeQuarter>
              <div>
                <h4>
                  About the book
                </h4>
                <p>
                  <span>Marketing needs a wake-up call or risks fading away!</span>
                </p>
                <p>
                  While most business functions continue to advance and evolve, it seems marketing is hidden in a cloud of 'digital everything'. The illusion of shiny new objects lures the industry to make quick, confused grabs and use old philosophies backed by aimless statistics.
                </p>
                <p>
                  This book aims to shake marketing by the collar. To pull it from the precipice and encourage action… before it's too late.
                </p>
                <p>
                  If you're a marketer, you'll understand – marketing is powerful. But unless you have the courage and conviction for using that power effectively, it will remain undervalued, underutilised, and underperforming in the broader business community.
                </p>
                <p>
                  Previously a confessed anti-marketing salesman who pulls no punches, Richard has redirected a lifetime passion for sales into the marketing lane. Now, he humbly shares how his perspective changed, and his eyes were opened to marketing's unrivalled commercial importance. 
                </p>
                <p>
                  Using a non-jargon narrative, the book is a provocative pep talk with actionable strategies to guide marketers of all levels and aspirations towards their best performance and help tip the scales in marketing's favour. Your marketing efforts must evolve. Using his book, you can take the first step towards making that happen therefore improving your and your organisation's marketing efforts.
                </p>
                <p>
                  <span>Richard Hadler, Author and CEO, alan. Agency</span>
                </p>
              </div>
            </ThreeQuarter>
          </Row>
        </Container>
      </Section>
    </React.Fragment>
  )
}

export default withTheme(About)
